<template>
  <div>
    <v-row>
      <v-col>
        <v-btn @click="Insert()">เพิ่ม</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item.ac="{ item }">
            <v-btn fab dark small color="green" @click="InsertDetail(item.RubberId)">
              <v-icon dark>fa fa-list</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn fab dark small color="lime" @click="Edit(item.RubberId)">
              <v-icon dark>fa fa-pen</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="Delete(item.RubberId)">
              <v-icon dark>fa fa-trash-alt</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [
        {
          text: "ชื่อแปลง",
          align: "left",
          sortable: true,
          value: "RubberName"
        },
        {
          text: "ที่ตั้ง",
          align: "left",
          sortable: true,
          value: "RubberAddress"
        },
        {
          text: "เจ้าของสวน",
          align: "left",
          sortable: true,
          width: 120,
          value: "OwnerName"
        },
        {
          text: "เปอร์เซ็น",
          align: "left",
          sortable: true,
          width: 100,
          value: "OwnerPercent"
        },
        {
          text: "คนกรีดยาง",
          align: "left",
          sortable: true,
          width: 120,
          value: "WorkkerName"
        },
        {
          text: "เปอร์เซ็น",
          align: "left",
          sortable: true,
          value: "WorkerPercent",
          width: 100
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "RubberRemark"
        },
        { width: 60, text: "", value: "ac", sortable: false },
        { width: 120, text: "", value: "action", sortable: false }
      ],
      owner: [],
      worker: [],
      desserts: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    Select() {
      axios.get("api/v1/select/select/rubber").then(res => {
        if (res.status == 200) {
          this.desserts = res.data;
        }
      });
    },

    Edit(v) {
      this.PassAccept("update", () => {
        this.$router.push("/rubber/add?RubberId=" + v);
      });
    },
    InsertDetail(v) {
      this.$router.push("/rubber/detail?RubberId=" + v);
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios.delete("/api/v1/delete/rubber?RubberId=" + v).then(res => {
          if (res.status == 200) {
            this.Select();
          }
        });
      });
    },
    Insert() {
      this.$router.push("/rubber/add");
    }
  }
};
</script>
<style></style>
